import React from "react";
import "./headline.css";
function Headline() {
  return (
    <>
      <div className="headline-div center">
        <h1 className="headline-h1">
          <b>
            Get Ready To Grow Your Business
            <u className="headline-u"> It's Already Too Late!</u>
          </b>
        </h1>
      </div>
    </>
  );
}

export default Headline;
