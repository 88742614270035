import React from "react";
import CaseStudy from "./CaseStudy";
import "./CaseStudiesSection.css";
import study1 from "./study1.JPG";
import study2 from "./study2.JPG";
import study3 from "./study3.JPG";
import study4 from "./study4.jpg"

const caseStudies = [
  {
    image: study1,
    title: "322 Website Leads at a Cost of Just £1.04 per Lead!",
    description:
      "When our client, who has an upcoming seminar, approached us to promote her Binge Eating course, she had a limited budget and high hopes. Leveraging the power of Facebook ads, we scaled her campaign from 0 to 322 leads with just £405 in ad spend. Our targeted strategies delivered impressive results, achieving a cost per lead (CPL) of only £1.04.",
    reverse: false,
  },
  {
    image: study2,
    title: "1,000+ Qualified Leads, $40K Revenue for Attorney",
    description:
      "Our attorney client struggled to find potential clients. In just four months, we helped him secure over 1,000 qualified leads through targeted Facebook ads. This influx of leads brought in high-quality clients and allowed him to outsource some, generating over $40K in revenue.",
    reverse: true,
  },
  {
    image: study3,
    title: "100+ Tickets Sold, R112K Revenue in One Month",
    description:
      "Our client, who organizes concert events in Cape Town, sold over 100 tickets in just one month with our help. This successful campaign generated R112K in revenue, showcasing the power of our marketing strategies.",
    reverse: false,
  },

  {
    image: study4,
    title: "7+ ROAS for a Women's Clothing Brand",
    description: "We helped a women's fashion brand boost their ROAS from 1 to over 7. With an ad spend of £43K, we generated around £300K in monthly revenue. Our strategic approach delivered exceptional results.",
    reverse: true
  }
  // Add more case studies as needed
];

const CaseStudiesSection = () => {
  return (
    <div className="case-studies-section">
      <div
        className="headline center"
        style={{
          color: "rgb(23, 23, 23)",
          marginBottom: "10px",
        }}
      >
        <b className="headline-simple-text">
          Our Systems Have Been
          <span className="headline-u">
            {" "}
            Crushing It For Over 50 Brands
          </span>
        </b>
      </div>

      {caseStudies.map((study, index) => (
        <CaseStudy
          key={index}
          image={study.image}
          title={study.title}
          description={study.description}
          reverse={study.reverse}
        />
      ))}
    </div>
  );
};

export default CaseStudiesSection;
