import React from "react";
import TestimonialCard from "./TestimonialCard";
import "./TestimonialSection.css";
import video1 from "./video-1.mp4";
import video2 from "./video-2.mp4";
import video3 from "./video-3.mp4";
import video4 from "./video-4.mp4";

const testimonials = [
  {
    videoUrl: video1,
    quote:
      "“ I am not usually the one to write reviews, but he knows what he is talking about, genuinely cares, and is very knowledgeable.”",
    name: "Jaime Dongo",
    title: "Founder of Mr Mortgage loans",
  },
  {
    videoUrl: video2,
    quote: "“Salman has been absolutely amazing. He managed my entire ad campaign, and I saw fascinating lead generation results.”",
    name: "Lucie Gray",
    title: "Founder of theselfloveacademy",
  },
  {
    videoUrl: video3,
    quote:
      "“He crushed it in every aspect of our projects. He finds out what will give you the best results rather than just finishing the job”",
    name: "Chase Wessendorf",
    title: "CEO of Macro Buildz",
  },
];

function getScreenWidth() {
  return window.innerWidth;
}

const TestimonialSection = () => {
  if (getScreenWidth() < 700) {
    return (
      <>
        <div className="testimonial-section">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              videoUrl={testimonial.videoUrl}
              quote={testimonial.quote}
              name={testimonial.name}
              title={testimonial.title}
              lastVideo={false}
            />
          ))}
          <TestimonialCard
            videoUrl={video4}
            quote={
              "“He has helped us a lot by running campaigns and optimizing funnels. He's a really cool dude.”"
            }
            name={"Christopher"}
            title={"Founder of Christoph Olivier Consulting"}
            lastVideo={true}
          ></TestimonialCard>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="testimonial-section">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              videoUrl={testimonial.videoUrl}
              quote={testimonial.quote}
              name={testimonial.name}
              title={testimonial.title}
              lastVideo={false}
            />
          ))}
        </div>
        <div className="center">
          <span className="headline-highlight-text">…And Even:</span>
        </div>
        <div className="testimonial-section">
          <TestimonialCard
            videoUrl={video4}
            quote={
              "“He has helped us a lot by running campaigns and optimizing funnels. He's a really cool dude.”"
            }
            name={"Christopher"}
            title={"Founder of Christoph Olivier Consulting"}
            lastVideo={true}
          ></TestimonialCard>
        </div>
      </>
    );
  }
};

export default TestimonialSection;
