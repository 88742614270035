import React from 'react';
import './LocalVideo.css'; // Create and import a CSS file for styling
import videoSrc from './SalmanLanding2.mp4'

const LocalVideo = () => {
  return (
    <div className="local-video-container">
      <video controls className="video"autoPlay="1">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default LocalVideo;