import React from "react";
import ClientTypeCard from "./ClientTypeCard";
import "./ClientTypeSection.css";

// Example icons, replace with actual paths to your icons
import iconCoaches from "../../../../icons/coaches.svg";
import iconConsultants from "../../../../icons/consultants.svg";
import iconCourseCreators from "../../../../icons/course_creators.svg";
import iconServiceProviders from "../../../../icons/service_provider.svg";
import iconInfopreneurs from "../../../../icons/Infopreneurs.svg";
import iconDigitalProductCreators from "../../../../icons/digital_product_creators.svg";
import iconDigitalMarketers from "../../../../icons/digital_marketers.svg";
import iconIndustryExperts from "../../../../icons/industry_expert.svg";
import iconAuthors from "../../../../icons/authors.svg";
import iconSpeakers from "../../../../icons/speakers.svg";

const clientTypes = [
  { icon: iconCoaches, title: "Coaches" },
  { icon: iconConsultants, title: "Consultants" },
  { icon: iconCourseCreators, title: "Course Creators" },
  { icon: iconServiceProviders, title: "Service Providers" },
  { icon: iconInfopreneurs, title: "Infopreneurs" },
  { icon: iconDigitalProductCreators, title: "Digital Product Creators" },
  { icon: iconDigitalMarketers, title: "Digital Marketers" },
  { icon: iconIndustryExperts, title: "Industry Experts" },
  { icon: iconAuthors, title: "Authors" },
  { icon: iconSpeakers, title: "Speakers" },
];

function getScreenWidth() {
  return window.innerWidth;
}

const ClientTypeSection = () => {
  let firstRows = clientTypes.slice(0, -5);
  let lastRow = clientTypes.slice(-5);

  if (getScreenWidth() < 700) {
    firstRows =  clientTypes.slice(0, -4);
    lastRow =  clientTypes.slice(-4);
  }
 
  console.log(getScreenWidth())
  return (
    <div className="client-type-section">
      
      <div className="client-type-grid">
        {firstRows.map((clientType, index) => (
          <ClientTypeCard
            key={index}
            icon={clientType.icon}
            title={clientType.title}
          />
        ))}
      </div>
      <div className="background-image-container">                                                 
        <img
          className="background-image"
          alt="transition"
          width=""
          src="https://www.conversionstudio.co/hosted/images/a4/18ebe95c594208af432167a46e4a97/hero-bg.svg"
        />
        <div className="overlay-grid">
          {lastRow.map((clientType, index) => (
            <ClientTypeCard
              key={index}
              icon={clientType.icon}
              title={clientType.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientTypeSection;
