import React from "react";
import Top1 from "./Top1";
import "./Top.css";
import TrustedPartners from "../Trusted-Partners-Section/TrustedPartners";
import Video from "./Video";

function Top() {
  return (
    <>
      <div className="Top-div">
        <div className="Top">
          <Top1></Top1>
          <TrustedPartners></TrustedPartners>
        </div>

        <div className="transition-container">
          <img
            className="top-transition"
            alt="Transition"
            src="https://www.conversionstudio.co/hosted/images/a4/18ebe95c594208af432167a46e4a97/hero-bg.svg"
          />
          <div className="video-div center">
            <Video></Video>
          </div>
        </div>
      </div>

      {/* <div className="transition-section"></div> */}
    </>
  );
}

export default Top;
