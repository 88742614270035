import React from "react";
import TestimonialSection from "./Testimonial-Section/TestimonialSection";
import ClientTypeSection from "./Testimonial-Section/ClientType-Section/ClientTypeSection";
import CaseStudiesSection from "../Case-Study-Section/CaseStudiesSection";
import ReviewsSection from "../Review-Section/ReviewsSection";
function ClientSection(props) {
  return (
    <>
      <div style={{marginTop: "10px"}} className="headline center">
        <b className="headline-simple-text">
          Don’t Just
          <span className="headline-highlight-text"> Take Our Word </span>
          For It
        </b>
      </div>

      <div className="sub-headline center">
        Here’s What Our Clients Are Saying About Us
      </div>

      <TestimonialSection></TestimonialSection>
      <ReviewsSection></ReviewsSection>
      <CaseStudiesSection></CaseStudiesSection>

      <div className="headline center">
        <b className="headline-simple-text">
          We Often Work With Clients Who Are:
        </b>
      </div>
      <div style={{position: "relative"}} className="center">
        <ClientTypeSection></ClientTypeSection>
      </div>
    </>
  );
}

export default ClientSection;
