import React from 'react';
import './CaseStudy.css';

const CaseStudy = ({ image, title, description, reverse }) => {
  return (
    <div className={`case-study ${reverse ? 'reverse' : ''}`}>
      <img src={image} alt="Case Study" className="case-study-image" />
      <div className="case-study-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default CaseStudy;
