import React from 'react';
import './TransitionSection.css'; // Create and import a CSS file for styling
import sideImage from './salman-transition.png'; // Import the side image (replace with the correct path)
import icon1 from './icon1.svg'
import icon2 from './icon2.svg'
import icon3 from './icon3.svg'
import icon4 from './icon4.svg'
import BookButton from '../Top/BookButton'



const TransitionSection = () => {
  return (
    <>
      <div className="transition-section-2">
        <div className="transition-content">
          <div className="transition-header">
            <h2>Could Your Business Be Our Next?</h2>
            <p>
              Let's Talk About What You Have In Mind! Book A Free, 30-Minute Marketing Planning Session On This Page.
            </p>
            <BookButton></BookButton>
        </div>
        <img src={sideImage} alt="Person smiling" className="side-image" />
        </div>
      </div>
      {/* <div className="transition-benefits">
        <div className="benefit-item">
          <img src={icon1} alt="Build trust with your prospects" />
          <p>Build trust with your prospects</p>
        </div>
        <div className="benefit-item">
          <img src={icon2} alt="Stand out from competitors" />
          <p>Stand out from competitors</p>
        </div>
        <div className="benefit-item">
          <img src={icon3} alt="Appear as a true authority" />
          <p>Appear as a true authority</p>
        </div>
        <div className="benefit-item">
          <img src={icon4} alt="Increase your sales & conversions" />
          <p>Increase your sales & conversions</p>
        </div>
      </div> */}
    </>
  );
};

export default TransitionSection;

