import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: "center",
  backgroundColor: "transparent",
  color: "white",
  boxShadow: "none",
  margin: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));

const Number = styled(Typography)(({ theme }) => ({
  fontSize: "3.2rem",
  fontFamily: "Silka Bold New",
  lineHeight: "1.3em",
  color: "#eca24e",
  fontWeight: "bold",
  [theme.breakpoints.down('sm')]: {
    fontSize: "1.625rem",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1.7rem",
  fontFamily: "Silka Bold New",
  lineHeight: "1.3em",
  [theme.breakpoints.down('sm')]: {
    fontSize: "1rem",
  },
}));

export default function RowAndColumnSpacing() {
  const stats = {
    yearsInBusiness: "4+",
    projectsCompleted: "120+",
    teamMembers: "8+",
    sales: "20M+",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container rowSpacing={4} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={6} sm={6} md={3}>
          <Item>
            <Number>{stats.yearsInBusiness}</Number>
            <Label>Years In Business</Label>
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item>
            <Number>{stats.projectsCompleted}</Number>
            <Label>Projects Completed</Label>
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item>
            <Number>{stats.teamMembers}</Number>
            <Label>Team Members</Label>
          </Item>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Item>
            <Number>{stats.sales}</Number>
            <Label>In Sales</Label>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
