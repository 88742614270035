import React from "react";
import "./BookButton.css";



function BookButton() {
  return (
    
    <>
      <div className="top-button-div center">
        <a href="https://calendly.com/salmanshafi9898/meeting-with-salman" target="_blank" rel="noopener noreferrer" className="top-button-a animation">
          <span className="top-button-s">
            Book My Free Consultation Session!
          </span>
        </a>
      </div>
    </>
  );
}

export default BookButton;
