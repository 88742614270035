import React from "react";
import "./CardSection.css";
import Card from "./Card";
import { FaTimes, FaCheck } from "react-icons/fa";
import { green } from "@mui/material/colors";

const points = {
  first: {
    p: [
      "Inexperienced Marketers",
      "Copy & pasted growth strategies",
      "Ignore changing market trends",
      "Inadequate communication",
      "Endless trial & error",
      "Lack of research on your industry",
      "Generic executions",
    ],
    icon: <FaTimes style={{color: "red"}}/>,
  },
  second: {
    p: [
      "4 Years of proven success",
      "Customized best-fit solutions",
      "Regularly adapting",
      "Frequent check-ins & updates",
      "Data backed strategies only",
      "Provides industry specific expertise",
      "Unique & original creative",
    ],
    icon: <FaCheck style={{color: "green"}}/>,
  },
};

const CardSection = () => {
  return (
    <div className="card-section">
      {Object.keys(points).map((key, index) => (
        <Card key={index} points={points[key].p} icon={points[key].icon} />
      ))}
    </div>
  );
};

export default CardSection;
