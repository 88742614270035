import React from 'react';
import './Card.css';

const Card = ({ points, icon }) => {
  return (
    <div className="card">
      <ul>
        {points.map((point, index) => (
          <li key={index}>
            <span className="icon">{icon}</span>
            {point}
          </li>
        ))}
      </ul>
    </div>
  );
};


export default Card;
