import React from "react";
import "./About.css";
import salman_about from "./salman_about.jpg";
function About() {
  return (
    <>
      <div className="about-main-div">
        <div className="about-headline-div">
          <h1 style={{ marginTop: 0 }}>
            <u className="about-headline-u">
              About your marketing strategy guide
            </u>
          </h1>
          <h1 className="about-headline-h1">
            <b>Hey! My name is Salman.</b>
          </h1>
          <div className="about-description">
            I help businesses achieve remarkable growth through powerful digital
            marketing strategies that turn strangers into leads and leads into
            loyal customers. I get it—trusting random marketing agencies can be
            risky. Online testimonials can easily be made through AI tools.
            That's why I rely on Upwork. You can't fake results there. Check out
            my Top Rated profile by <a style={{textDecoration: "none", color: "#eca24e" }} href="https://www.upwork.com/freelancers/~017b9c857770efbbbf?viewMode=1"  target="_blank" rel="noopener noreferrer"> clicking here.</a>
            <br />
            <br />
            My philosophy is:
            <br />
            <br />
            <i>
              “Sure, your marketing campaigns may be performing well already…but
              can you imagine how much better they would perform with the right
              strategies and execution?”
            </i>
            <br />
            <br />
            …And so that’s what I – and my team at my marketing agency, Just
            Konvert– do. If you’re looking to maximize the revenue potential
            your business sees from its marketing efforts, then I can’t wait to
            help you do that :)
          </div>
        </div>
        <div className="about-picture">
          <img src={salman_about} alt="About Salman" />
        </div>
      </div>
    </>
  );
}

export default About;
