import React from 'react';
import './TeamCard.css';

const TeamCard = ({ name, role, image, gradientType }) => {
  const getGradient = (type) => {
    switch (type) {
      case 'black':
        return 'linear-gradient(180deg, #2E2E2D 0%, #4D4D4D 100%)';
      case 'orange':
        return 'linear-gradient(180deg, #ECA24E 0%, #EC7D4E 100%)';
      case 'white':
        return 'rgb(250, 247, 243)';
      default:
        return 'rgb(250, 247, 243)';
    }
  };

  const getTextColor = (type) => {
    switch (type) {
      case 'black':
        return { nameColor: 'rgb(236, 162, 78)', roleColor: 'white' };
      case 'orange':
        return { nameColor: 'rgb(35, 35, 34)', roleColor: 'white' };
      case 'white':
        return { nameColor: 'rgb(236, 162, 78)', roleColor: 'rgb(35, 35, 34)' };
      default:
        return { nameColor: 'rgb(35, 35, 34)', roleColor: 'rgb(35, 35, 34)' };
    }
  };

  const gradientStyle = {
    background: getGradient(gradientType)
  };

  const { nameColor, roleColor } = getTextColor(gradientType);

  return (
    <div className="team-card" style={gradientStyle}>
      <img src={image} alt={`${name}`} className="team-card-image" />
      <div className="team-card-content">
        <h3 className="team-card-name" style={{ color: nameColor }}>{name}</h3>
        <p className="team-card-role" style={{ color: roleColor }}>{role}</p>
      </div>
    </div>
  );
};

export default TeamCard;
