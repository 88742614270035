import React from 'react';
import './ClientTypeCard.css';

const ClientTypeCard = ({ icon, title }) => {
  return (
    <div className="client-type-card">
      <img src={icon} alt={title} className="client-type-icon" />
      <p className="client-type-title">{title}</p>
    </div>
  );
};

export default ClientTypeCard;
