import "./styles.css";
import Top from "./components/Top/Top";
import Headline from "./components/prev-work/headline";
import RowAndColumnSpacing from "./components/prev-work/stats";
import Video from "./components/Top/Video";
import BookButton from "./components/Top/BookButton";
import ClientSection from "./components/Client-Section/ClientSection";
import Choose from "./components/Choose-Section/Choose";
import TeamSection from "./components/Teams-Section/TeamSection";
import About from "./components/About-Section/About";
import Footer from "./components/footer/Footer";
import TransitionSection from "./components/TransitionSection/TransitionSection";
import TransitionBenefits from "./components/TransitionSection/Transition-benefits";

export default function App() {
  return (
    <>
      <Top></Top>
      <div className="section">
        <div className="Second-section center">
          <Headline></Headline>
        </div>
        <div className="stats center">
          <RowAndColumnSpacing></RowAndColumnSpacing>
        </div>
        <div className="sub-headline-div center Second-section ">
          <p>
            Because while you waited, we've spent{" "}
            <u className="headline-u">4 years</u> perfecting our craft,
            completing over <u className="headline-u">120+ projects</u> with a
            team of <u className="headline-u">8+ experts</u>, and driving a
            staggering <u className="headline-u">20 Million</u> in sales. Don't
            miss another moment to scale your success with us!
          </p>
        </div>
        <BookButton></BookButton>
      </div>

      <div className="transition-section-div">
        <TransitionSection></TransitionSection>
        {/* <img
          width="100%"
          height="300px"
          alt="Transition"
          src="https://www.conversionstudio.co/hosted/images/c8/111276c3954f0da8f6b0a6f0145749/bottom-bg.svg"
        /> */}
      </div>
      <div className="transition-section-div-2">
        <TransitionBenefits></TransitionBenefits>
      </div>

      <div className="Client-Section">
        <ClientSection></ClientSection>
      </div>
      <div className="Choose-Section">
        <div className="meet headline center">
          <b className="headline-simple-text">
            Meet Our Team That{" "}
            <span className="headline-u"> Powers Your Success </span>
          </b>
        </div>
        <TeamSection></TeamSection>
        <Choose></Choose>
      </div>

      <div className="about">
        <About></About>
      </div>
      <div className="footer-section">
        <Footer></Footer>
      </div>
    </>
  );
}

// https://www.conversionstudio.co/hosted/images/c8/111276c3954f0da8f6b0a6f0145749/bottom-bg.svg
