import React from "react";
import TeamCard from "./TeamCard";
import "./TeamSection.css";
import salman from './salman.jpg'
import media from './media.jpg'
import website from './website.jpg'
import graphic from './graphic.jpg'
import strategy from './strategy.jpg'
import video from './video.jpg'

const teamMembers = [
  {
    name: "Salman Shafi",
    role: "Founder",
    image:
      salman,
    gradientType: "black",
  },
  {
    name: "Syed Muhammad",
    role: "Media Buyer",
    image: media,
    gradientType: "black",
  },
  {
    name: "Anish Khurram",
    role: "Graphic Designer",
    image: graphic,
    gradientType: "black",
  },
  {
    name: "Usama Saif",
    role: "Senior Creative Strategist",
    image: strategy,
    gradientType: "black",
  },
  {
    name: "Raiyaan Hamid",
    role: "Website Developer",
    image: website,
    gradientType: "black",
  },
  {
    name: "Junaid Alam",
    role: "Video Editor",
    image: video,
    gradientType: "black",
  }
];

const TeamSection = () => {
  return (
    <div className="team-section">
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <TeamCard
            key={index}
            name={member.name}
            role={member.role}
            image={member.image}
            gradientType={member.gradientType}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
