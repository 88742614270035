import React from "react";
import ReactPlayer from "react-player";
import "./TestimonialCard.css";


const color_black = {
  color: "rgb(89, 89, 89)"
};


const color_orange = { colsor: "#eca24e" };

const TestimonialCard = ({ videoUrl, quote, name, title, lastVideo }) => {
    const last_video_name_style = {
        fontWeight: 'bold',
        textAlign: 'center',
        ...(lastVideo && color_black)
    }
    
    const video_name_style = {
        textAlign: 'left',
        ...color_orange
    }

    const last_video_title_style = {
        textAlign : 'center',
        ...color_black,
        fontSize : '16px'
    }
    const video_title_style = {
        textAlign : 'left',
        ...color_orange,
       
    }
  return (
    <div className="testimonial-card">
      
      <div
        className="player-wrapper"
      >
        <ReactPlayer
          className="react-player"
          url={videoUrl}
          width="100%"
          height="100%"
          controls={true}
          muted={true}
          loop={true}
          playing={true}
        />
      </div>
      <p className="quote">
        {quote}
      </p>
      <p className="name">— {name}</p>
      <p className="title">
        {title}
      </p>
    </div>
  );
};

export default TestimonialCard;
