import React from "react";
import "./TrustedPartners.css";
import googleLogo from "./google.jpg";
import metaLogo from "./meta.jpg";
import upworkLogo from "./upwork.png";


const TrustedPartners = () => {
  return (
    <div className="trusted-partners">
      <div className="trusted-partners-content">
        <div className="headline center trusted-partners-title" style={{}}>
          <b className="headline-simple-text" style={{fontSize: "35px"}}>
            Accreditations
            <span className="headline-highlight-text" style={{fontSize: "35px"}}> We've Earned</span>
          </b>
        </div>
        <div className="trusted-partners-logos">
          <img src={googleLogo} alt="Google" className="partner-logo" />
          <img src={upworkLogo} alt="Upwork" className="partner-logo" />
          <img src={metaLogo} alt="Meta" className="partner-logo" />
        </div>
      </div>
    </div>
  );
};

export default TrustedPartners;
