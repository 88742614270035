import React from "react";
import "./ReviewsSection.css";
import review1 from "./review1.JPG";
import review2 from "./review1.JPG";
import review3 from "./review1.JPG";
import review4 from "./review1.JPG";

const reviews = [
  review1,
  review2,
  review3,
  review4,
  // Add more review images as needed
];

const ReviewsSection = () => {
  return (
    <div className="reviews-section">
      <div
        className="headline center"
        style={{
          color: "rgb(23, 23, 23)",
        }}
      >
        <b className="headline-simple-text">
          <span className="headline-u">
            {" "}
            At Justkonvert, we like to over-deliver.{" "}
          </span>
          But don’t just take it from us…
        </b>
      </div>
      <div className="reviews-grid">
        {reviews.map((review, index) => (
          <img
            key={index}
            src={review}
            alt={`Review ${index + 1}`}
            className="review-image"
          />
        ))}
      </div>
    </div>
  );
};

export default ReviewsSection;
