import React from 'react';
import './Footer.css';
import logo from './footer_logo.png'; // Add the correct path to your logo file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={logo} alt="Just Konvert Logo" className="footer-logo" style={{backgroundColor: "transparent"}} />
        <p>Copyright © 2024 | Just Konvert</p>
        <p>
          All Rights Reserved | <a href="/terms-of-use">Terms of Use</a> | <a href="/privacy-policy">Privacy Policy</a> | <a href="/disclaimer">Disclaimer</a>
        </p>
        <p>support@justkonvert.co</p>
        <p>
        JustKonvert operates independently from the social media platform owned by Meta Platforms, Inc. We are not affiliated with or endorsed by Meta Platforms, Inc. Meta and its associated trademarks are owned by Meta Platforms, Inc.
        </p>
        <p>Designed & Developed by Ahmad Bilal</p>
      </div>
    </footer>
  );
};

export default Footer;
